import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import {
  Section,
  ImageBasic,
  Grid,
  Signature,
  Button,
  Breakpoints,
} from 'inno-components'
import chroma from 'chroma-js'
import Config from '../../Config'


import Royal from '../clubs/Royal'
import Churchill from '../clubs/Churchill'
import Montjoie from '../clubs/Montjoie'

const styles = StyleSheet.create({
  bold: {
    fontWeight: 'bold',
  },
  section: {
    padding: '2em 4em',
    [Breakpoints.small]: {
      padding: '1.5em 1.5em',
    },
  },
  sectionTop: {
    backgroundImage:
      'url(https://storage.googleapis.com/innobelge-websites.appspot.com/sites/stages-tennis/images/bg.jpg)',
    backgroundSize: 'cover',
  },
  sectionMiddle: {
    backgroundColor: Config.colors.thirdary,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  topBar: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1.5em 0',
  },
  topBarContainer: {
    padding: '0.5em',
  },
  logo: {
    width: '150px',
    height: 'auto',
  },
  logoFacebook: {
    width: '35px',
    height: '35px',
    borderRadius: '50%',
  },
  logoWhatsapp:{
    width: '35px',
    height: '35px',
    float: 'left',
    border: '5px solid #022B47',
  },
  header: {
    maxWidth: '1000px',
    height: 'auto',
    margin: '4em',
    [Breakpoints.small]: {
      maxWidth: '350px',
      margin: '2em',
    },
  },
  contentWrapper: {
    maxWidth: '850px',
    margin: 'auto',
  },
  titre: {
    fontSize: '2em',
    margintop: '2em',
    color: Config.colors.primary,
  },
  soustitre: {
    fontSize: '1.5em',
    color: Config.colors.primary,
  },
  image: {
    width: '100%',
    height: 'auto',
    marginTop: '2em 3em',
  },
  images: {
    margin: '3em 0',
  },
  tuile: {
    display: 'block',
    maxWidth: '180px',
    width: '180px',
    height: 'auto',
    margin: 'auto',
  },
  tuile1: {
    display: 'block',
    maxWidth: '300px',
    width: '360px',
    height: 'auto',
    margin: 'auto',
  },
  description: {
    display: 'block',
    backgroundColor: '#fff',
    padding: '1em',
    borderRadius: '1em',
    marginTop: '1em',
    border: `solid 2px ${chroma('#9ec6fb')
      .alpha(0.6)
      .css()}`,
  },
  clubs: {
    margin: '2em 0',
  },
  cours: {
    display: 'block',
    textAlign: 'center',
  },
  coursImage: {
    maxWidth: '100px',
    height: 'auto',
    margin: 'auto',
  },
  coursTitre: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  ul: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  footer: {
    color: '#fff',
    backgroundColor: Config.colors.primary,
  },
  highlight: {
    color: Config.colors.secondary,
    fontWeight: 'bold',
    fontSize: '1.1em',
  },
  lien: {
    color: '#fff',
    textDecoration: 'none',
  },
  lienPrimary: {
    color: '#000',
    fontWeight: 'bold',
    textDecoration: 'none',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifycontent: 'center',
    marginBottom: '4em',
  },
  button: {
    backgroundColor: Config.colors.primary,
    color: Config.colors.secondary,
    padding: '1em',
    fontWeight: 'bold',
    [Breakpoints.small]: {
      padding: '0.7em',
    },
  },
  titreOrange: {
    color: Config.colors.secondary,
  },
  signature: {
    color: '#FFFFFF',
    textDecoration: 'none',
  },
  iconSignature: {
    color: '#FC0D1B',
  },
})
class Home extends Component {
  render() {
    return (
      <div>
        <section className={css(styles.section, styles.sectionTop)}>
          <div className={css(styles.topBarContainer, styles.contentWrapper)}>
            <div className={css(styles.topBar)}>
              <ImageBasic
                className={css(styles.logo)}
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/stages-tennis/images/logo.png"
              />
              <a
                href="https://www.facebook.com/brusselstennisschool"
                target="_blank"
                rel="noopener"
                title="Facebook"
              >
                <ImageBasic
                  className={css(styles.logoFacebook)}
                  src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/stages-tennis/images/facebook.png"
                />
              </a>
            </div>
          </div>
          <div className={css(styles.container)}>
            <ImageBasic
              className={css(styles.header)}
              src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/stages-tennis/images/stages-2024-2025.png"
            />
            <div className={css(styles.buttonContainer)}>
              <Button className={css(styles.button)} href="tel:+32476012399">
                Contactez Nous au 0476.01.23.99
              </Button>
            </div>
          </div>
        </section>
        <section className={css(styles.section, styles.sectionMiddle)}>
          <div className={css(styles.contentWrapper)}>
            <h2 className={css(styles.titre)}>Bonjour à tous,</h2>
            <p>
              Bienvenue sur le site dédié aux{' '}<span className={css(styles.bold)}>stages 2024</span> de la Brussels Tennis School, 
              qui se dérouleront sur 3 sites :
            </p>
            <Grid columns={3} stackable className={css(styles.clubs)}>
              <Grid.Row>
                <Grid.Column>
                  <Royal />
                </Grid.Column>
                <Grid.Column>
                  <Churchill />
                </Grid.Column>
                <Grid.Column>
                  <Montjoie />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <p>
              Ces stages accessibles aux enfants âgés de 3,5 à 17 ans, 
              seront organisés toutes les semaines des vacances et comme chaque année, 
              les aspects ludiques, humains et éducatifs constitueront nos priorités !
            </p>
            <p>
              Tennis, cours d’expression artistique, multisports, plaine de jeux, château 
              gonflable sont au menu d’une formule qui connait un grand succès depuis plusieurs années.
            </p>
            <p>
              Inscriptions via <span className={css(styles.bold)}>WhatsApp</span> au <span className={css(styles.bold)}>0476 01 23 99</span>, 
              en communiquant le nom et prénom de l’enfant, 
              le n° de semaine et le club.
            </p>
            <p>
              N'hésitez pas à nous contacter par téléphone au{' '}
              <a href="tel:+32476012399" className={css(styles.lienPrimary)}>
                +32 476 01 23 99 
              </a>{' '} ou par e- mail:{' '}
              <a
                href="info@brusselstennisschool.be"
                className={css(styles.lienPrimary)}
              >
                info@brusselstennisschool.be
              </a>{' '} pour toute demande d'inscription ou d'informations complémentaires.

            </p>
            <p>
            Vous pouvez également vous inscrire sur notre site {' '}
              <a
                href="https://www.brusselstennisschool.be/stages-de-tennis"
                target="_blank"
                rel="noreferrer noopener"
                className={css(styles.lienPrimary)}
              >
                www.brusselstennisschool.be/stages-de-tennis
              </a>{' '} en remplissant la demande d’adhésion on-line.
            </p>
            
            <Grid className={css(styles.images)}>
              <Grid.Row centered>
                <Grid.Column width={6} mobile={8}>
                  <ImageBasic
                    className={css(styles.image)}
                    src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/stages-tennis/images/player1.jpeg"
                  />
                </Grid.Column>
                <Grid.Column width={6} mobile={8}>
                  <ImageBasic
                    className={css(styles.image)}
                    src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/stages-tennis/images/player2.jpeg"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid className={css(styles.images)} stackable>
              <Grid.Row centered>
                <Grid.Column width={10}>
                  <ImageBasic
                    className={css(styles.tuile1)}
                    src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/stages-tennis/images/DATESSTAGES20242025.png"
                  />
                  <div className={css(styles.description)}>
                    

                  <strong>Congé d’automne (Toussaint)</strong>
                  <div style={{ padding: 5 }}></div>
                  <ul className={css(styles.ul)}>
                      <li><strong>Stage Toussaint 1 :</strong> du lundi 21 au vendredi 25 octobre 2024</li>
                      <li><strong>Stage Toussaint 2 :</strong> du lundi 28 au vendredi 1er novembre 2024</li>
                  </ul>
                  <div style={{ padding: 10 }}></div>
                  <strong>Vacances d’hiver (Noël)</strong>
                  <div style={{ padding: 5 }}></div>
                  <ul className={css(styles.ul)}>
                      <li><strong>Stage Noël :</strong> du lundi 23 au vendredi 27 décembre 2024 (4 jours - pas de stage le 25/12)</li>
                      <li><strong>Stage Nouvel an :</strong> du lundi 30 décembre au vendredi 3 janvier 2024 (4 jours - pas de stage le 1er janvier)</li>
                  </ul>
                  <div style={{ padding: 10 }}></div>
                  <strong>Congé de détente (Carnaval)</strong>
                  <div style={{ padding: 5 }}></div>
                  <ul className={css(styles.ul)}>
                      <li><strong>Stage Carnaval 1 :</strong> du lundi 24 février au vendredi 28 février 2025</li>
                      <li><strong>Stage Carnaval 2 :</strong> du lundi 3 mars au vendredi 7 mars 2024</li>
                  </ul>
                  <div style={{ padding: 10 }}></div>
                  <strong>Vacances de printemps (Pâques)</strong>
                  <div style={{ padding: 5 }}></div>
                  <ul className={css(styles.ul)}>
                      <li><strong>Stage Pâques 1 :</strong> du lundi 28 avril au vendredi 2 mai 2025</li>
                      <li><strong>Stage Pâques 2 :</strong> du lundi 5 au vendredi 9 mai 2024</li>
                  </ul>
                  <div style={{ padding: 10 }}></div>
                  <strong>Stages été</strong>
                  <div style={{ padding: 5 }}></div>
                  <ul className={css(styles.ul)}>
                      <li><strong>Stage été 1 :</strong> du lundi 30 juin au vendredi 4 juillet 2025</li>
                      <li><strong>Stage été 2 :</strong> du lundi 7 au vendredi 11 juillet 2025</li>
                      <li><strong>Stage été 3 :</strong> du lundi 14 au vendredi 18 juillet 2025</li>
                      <li><strong>Stage été 4 :</strong> du mardi 22 au vendredi 25 juillet 2025 (4 jours - pas de stage le 21/7)</li>
                      <li><strong>Stage été 5 :</strong> du lundi 29 juillet au vendredi 1er août 2025</li>
                      <li><strong>Stage été 6 :</strong> du lundi 4 au vendredi 8 août 2025</li>
                      <li><strong>Stage été 7 :</strong> du lundi 11 au jeudi 14 août 2025 (4 jours - pas de stage le 15/8)</li>
                      <li><strong>Stage été 8 :</strong> du lundi 18 au vendredi 22 août 2025</li>
                      <li><strong>Stage été 9 :</strong> du lundi 25 au vendredi 29 août 2025</li>
                  </ul>

                      
                  </div>
                </Grid.Column>
                <Grid.Column width={6}>
                  <ImageBasic
                    className={css(styles.tuile1)}
                    src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/stages-tennis/images/GROUPES7-N.png"
                  />
                  <div className={css(styles.description)}>
                    <ul className={css(styles.ul)}>
                      <li>
                        <span className={css(styles.bold)}>G0: </span> 3 ans
                      </li>
                      <li>
                        <span className={css(styles.bold)}>G1: </span> 4-5 ans
                      </li>
                      <li>
                        <span className={css(styles.bold)}>G2: </span> 6-7 ans
                      </li>
                      <li>
                        <span className={css(styles.bold)}>G3: </span> 8-9 ans
                      </li>
                      <li>
                        <span className={css(styles.bold)}>G4: </span> 10-12 ans
                      </li>
                      <li>
                        <span className={css(styles.bold)}>G5: </span>{' '}
                        compétition
                      </li>
                      <li>
                        <span className={css(styles.bold)}>G6: </span> 12 ans et
                        +
                      </li>
                    </ul>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <div className={css(styles.cours)}>
                    <ImageBasic
                      className={css(styles.coursImage)}
                      src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/stages-tennis/images/palette.png"
                    />
                    <p className={css(styles.coursTitre)}>
                      + Cours d'art 1H par jour
                    </p>
                  </div>
                </Grid.Column>
                <Grid.Column width={8}>
                  <div className={css(styles.cours)}>
                    <ImageBasic
                      className={css(styles.coursImage)}
                      src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/stages-tennis/images/soccer-ball.png"
                    />
                    <p className={css(styles.coursTitre)}>
                      + 1H de multisports par jour
                    </p>
                  </div>
                </Grid.Column>
                <Grid.Column width={8}>
                  <div className={css(styles.cours)}>
                    <ImageBasic
                      className={css(styles.coursImage)}
                      src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/stages-tennis/images/chateau-gonflable.png"
                    />
                    <p className={css(styles.coursTitre)}>
                      + 1H de Plaine de jeux ou château gonflable
                    </p>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <h2 className={css(styles.titre)}>
              Description d'une journée type
            </h2>
            <Grid stackable>
              <Grid.Row columns={3}>
                <Grid.Column>
                  <h3 className={css(styles.soustitre)}>Pour G0 - G1 - G2</h3>
                  <ul className={css(styles.ul)}>
                    <li>9h-9h30 Echauffement</li>
                    <li>9h30-11h Tennis</li>
                    <li>11h-12h Cours d’expression artistique</li>
                    <li>12h-13h Repas</li>
                    <li>13h-14h Tennis et psychomotricité</li>
                    <li>14h-15h Plaine de jeux ou château gonflable</li>
                    <li>15h-16h Tennis</li>
                  </ul>
                </Grid.Column>
                <Grid.Column>
                  <h3 className={css(styles.soustitre)}>Pour G3 - G4 - G6</h3>
                  <ul className={css(styles.ul)}>
                    <li>9h-9h30 Echauffement</li>
                    <li>9h30-12h Tennis</li>
                    <li>12h-13h Repas</li>
                    <li>13h-14h Tennis</li>
                    <li>14h-15h Multisports</li>
                    <li>15h-16h Tennis</li>
                  </ul>
                </Grid.Column>
                <Grid.Column>
                  <h3 className={css(styles.soustitre)}>Pour G5</h3>
                  <ul className={css(styles.ul)}>
                    <li>
                      10h30-11h échauffement + entrainement physique spécifique
                    </li>
                    <li>11h-13h entrainement Tennis</li>
                    <li>13h-14h Repas + départ en tournoi officiel</li>
                    <li>14h-15h30 Entrainement Tennis</li>
                    <li>
                      15h30-16h Récupération, étirements et assouplissements
                    </li>
                  </ul>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <h2 className={css(styles.titre)}>Prix et renseignements</h2>
                  <ul>
                    <li>
                        Prix: Pour G1 G2 G3 G4 G6 : 180€ sans repas (collations offertes aux G1 G2 G3), 215€ avec repas chauds
                    </li>
                    <li>G5 200 euros sans repas</li>
                    <li>Réduction de 20 euros pour le deuxième enfant</li>
                    <li>
                      Garderie gratuite entre 8h et 9h et entre 16h et 17h
                    </li>
                  </ul>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </section>
        <footer>
          <section className={css(styles.section, styles.footer)}>
            <div className={css(styles.contentWrapper)}>
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <div className={css(styles.topBar)}>
                      <ImageBasic
                        className={css(styles.logo)}
                        src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/stages-tennis/images/logo-blanc.png"
                      />
                      <a
                        href="https://www.facebook.com/brusselstennisschool"
                        target="_blank"
                        rel="noopener"
                        title="Facebook"
                      >
                        <ImageBasic
                          className={css(styles.logoFacebook)}
                          src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/stages-tennis/images/facebook.png"
                        />
                      </a>
                    </div>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={16}>
                    <h2 className={css(styles.titre, styles.titreOrange)}>
                      Contact
                    </h2>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <div>
                      <ImageBasic
                        className={css(styles.logoWhatsapp)}
                        src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/stages-tennis/images/whatsapp2.png"
                      /> Inscriptions via WhatsApp au 0476 01 23 99, en communiquant les coordonnées complètes de l’élève
                    </div>
                    <p>
                      <a href="tel:+32476012399" className={css(styles.lien)}>
                        +32 476 01 23 99
                      </a>
                      <br />
                      <a
                        href="info@brusselstennisschool.be"
                        className={css(styles.lien)}
                      >
                        info@brusselstennisschool.be
                      </a>
                      <br />
                      <a
                        href="cthys@brusselstennisschool.be"
                        className={css(styles.lien)}
                      >
                        cthys@brusselstennisschool.be
                      </a>
                      <br />
                    </p>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={3}>
                  <Grid.Column>
                    <p>
                      ROYAL BRUSSELS LAWN TENNIS CLUB
                      <br />
                      CHAUSSÉE DE WATERLOO, 890
                      <br />
                      1000 BRUXELLES
                    </p>
                  </Grid.Column>
                  <Grid.Column>
                    <p>
                      TENNIS CLUB UCCLE CHURCHILL
                      <br />
                      RUE EDITH CAVELL 92
                      <br />
                      1180 UCCLE
                    </p>
                  </Grid.Column>
                  <Grid.Column>
                    <p>
                      PADEL TENNIS CLUB MONTJOIE
                      <br />
                      RUE EDITH CAVELL 91
                      <br />
                      1180 UCCLE
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <div style={{ padding: '2em', textAlign: 'center' }}>
                      <Signature
                        className={css(styles.signature)}
                        iconClassName={css(styles.iconSignature)}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </section>
        </footer>
      </div>
    )
  }
}

export default Home
